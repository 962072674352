<div class="self_test">
<div class="self_test__buttons">
 <div class="self_test__tab" >Afgeronde testen</div>
  <div class="self_test__tab advice" (click)="navigateToSelfTestAdvice()" >Advies</div>
</div>

  <p class="self_test__description">
    Wat goed dat jij aan jezelf en jouw onderneming werkt! Hieronder zie je de rapportcijfers van jouw voltooide zzp-zelftest(en).
  </p>

  <div class="self_test__table">
    <table class="custom-table">
      <thead>
      <th class="table-head-none"></th>
      <ng-container *ngFor="let date of selectedSelfTests.getValue(); index as i">
        <th class="self_test__table__head" [class.custom-table__first-table-head]="i === 0" matTooltip="{{ isImpersonated() ? '' : 'De testcode is:' + ' ' +  date.code}} " [matTooltipPosition]="'above'" >
          {{this.getLocalDate(date.completedDate)}}
        </th>
      </ng-container>
      </thead>

    <tbody>
    <ng-container  *ngFor="let selfTests of selectedSelfTests.getValue(); index as i;"  >
      <ng-container *ngFor="let score of selfTests.scores">
      <tr class="table__row">
        <th class="table__theme_row table-align-left theme-border" scope="row" >{{AccordionThemes[score.group]}}</th>
          <td class="custom-table__score" >{{getDecimal(score.average)}}</td>
      </tr>
      </ng-container>
    </ng-container>
    </tbody>
    </table>
    <div class="self_test__results">
    </div>
    </div>

  <ng-container *ngIf="selfTests.length > 2">
  <div class="self_test__toggle">
    <div class="self_test__toggle__btn">
      <label class="switch">
        <form [formGroup]="toggleButton">
          <input type="checkbox" formControlName="isToggled" [checked]="toggleButton.controls.isToggled.getRawValue()" >
          <span class="slider round"></span>
        </form>
      </label>
    </div>
  </div>
  </ng-container>
</div>
