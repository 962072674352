import {Injectable} from '@angular/core';
import {AuthCredentials} from './auth.model';
import {UserDetails} from './user.service';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private cookieService:CookieService) { }

  clearAllCredentials() {
    window.localStorage.clear();
    window.sessionStorage.clear();
  }

  storeAuthCredentials(credentials: AuthCredentials) {
    try {
      window.localStorage.setItem('authCredentials', JSON.stringify(credentials));
    } catch {
      throw new Error('Could not set local storage for credentials');
    }
  }

  retrieveAuthCredentials(): AuthCredentials {
    let authCredentials: AuthCredentials;
    const credentials = window.localStorage.getItem('authCredentials');
    if (credentials) {
      authCredentials = JSON.parse(credentials);
    } else {
      const impersonateCookie = this.cookieService.get('SPT');
      if (impersonateCookie) {
        authCredentials = {
          expires_at: -1,
          expires_in: 3600000, // 1 Hour
          access_token: impersonateCookie,
          refresh_token: null,
          roles: [],
          username: 'impersonate@sharepeople.com',
          token_type: '',
        };
        this.storeAuthCredentials(authCredentials);
        this.cookieService.delete('SPT');
      }
    }
    return authCredentials;
  }

  setUserDetails(userDetails: UserDetails): void {
    try {
      window.localStorage.setItem('userDetails', JSON.stringify(userDetails));
    } catch {
      throw new Error('Could not set local storage for user details');
    }
  }

  getUserDetails(): any {
    const userDetails = window.localStorage.getItem('userDetails');
    if (userDetails) {
      return JSON.parse(userDetails);
    } else {
      return {access_token: null};
    }
  }
}
