import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../../core-module/auth.service';
import {ThemeService} from '../../../../../sp-shared-lib/src/lib/shared/services/theme.service';

@Component({
  selector: 'sp-portal-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {

  sideMenuShown = false;
  fullName$: Observable<string>;
  media: Observable<any>;
  constructor(
    private router: Router,
    private themeService: ThemeService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.media = this.themeService.getLogo();
  }

  activePage(page: string) {
    return this.router.url === page;
  }

  logout() {
    this.authService.logout();
  }

  gotoDashboard() {
    this.router.navigate(['/dashboard'])
      .then(
        () => {
          this.sideMenuShown = false;
        }
      );
  }

  gotoFAQ() {
    window.open('https://www.sharepeople.nl/faq-dp0qhc8-l3/', '_blank');
  }

  gotoProfile() {
    this.router.navigate(['dashboard/profile'])
      .then(
        () => {
          this.sideMenuShown = false;
        }
      );
  }

  gotoDonations() {
    this.router.navigate(['dashboard/contribution-history'])
      .then(
        () => {
          this.sideMenuShown = false;
        }
      );
  }

  isImpersonated() {
    return this.authService.getAuth()?.username === 'impersonate@sharepeople.com';
  }

  logoutImpersonate(): void {
    this.authService.logoutImpersonate();
  }
}
